.list-item-container{
    background-color: transparent;
    height:12rem;
    border-bottom: .1rem solid white;
    color:white;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-left:1rem;
    padding-right:1rem;
    margin-top:5rem;
}
.list-title-container{
    height:65%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
}
.list-item-container:hover{
    cursor:pointer;
}
.list-item-container h2{
    font-size:4rem;
    font-weight:300;
    margin:0;
}
.list-item-container p{
    margin:0;
    font-weight:800;
}
.list-item-container a{
    font-size:3rem;
}
.cursor {
    transform: translate(-50%, -50%);
    position: fixed;
    left: -5rem;
    top: -15rem;
    width: 35rem;
    border-radius: 16px;
    background-color: white;
    z-index:-1;
    opacity:1;
  }
.nope{
    transition: ease-in-out .3s;
    opacity: 0;
    display:none;
}