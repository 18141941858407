#portfolio-container{
    height:auto;
    width:100%;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    z-index:0;
    margin-top:3rem;
    padding-top:5rem;
    padding-bottom:5rem;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}
#portfolio-line{
    width:80%;
    height:1px;
    background-color: white;
    margin-top:1rem;
    margin-bottom:3rem;
    color:white;
}
#portfolio-container-inner{
    height:auto;
    width:55rem;
}
.middle-portfolio{
    background-color: transparent;
}
.first{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
.first-img{
    position:relative;
    width:100%;
    height:35rem;
    overflow: hidden !important;
    background-repeat: no-repeat;
    background-position: center; 
    background-size:cover;
    background-image: url(../../../assets/long/one.jpg);
}
.second-img{
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    width:15rem;
    height:15rem;
    overflow: hidden !important;
    background-repeat: no-repeat;
    background-position: center; 
    background-size:cover;
    background-image: url(../../../assets/one.jpg);
}
.middle-img{
    margin-top:-6rem !important;
    z-index:-1;
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:20rem;
    overflow: hidden !important;
    background-repeat: no-repeat;
    background-position: center; 
    background-size:cover;
    background-image: url(../../../assets/one.jpg);
}
.middle-img img{
    top:0;
    width:100%;
    position:absolute;
}
.first-img img{
    top:0;
    position:absolute;
    width:100%;
}
.second-img img{
    top:0;
    position:absolute;
    width:80%;
}
.first ul{
    color:white;
    transition: ease-in-out;
    list-style-type: none;
    margin-top:0;
}
.first ul li{
    font-size:1.5rem;
    margin-top:.5rem;
    text-align:right;
    transition: ease-in-out;
}
#portfolio-title{
    margin-bottom:0;
    color:white;
    text-align: left;
    font-size: 10rem;
    z-index:1 !important;
}