.contact-container{
    background-color: black;
    color:white;
    width:100%;
    height:20rem;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:5rem;
    transition:ease-in-out .2s;
    overflow: hidden;
    margin:0 !important;
    position: relative;
}
.contact-container h2{
    font-weight: 100;
}
.contact-container:hover{
    cursor: pointer;
    background-color: white;
    color:black;
}
#contact-line{
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    width:90%;
    height:10rem;
}