#menu-icon{
    /*size*/
    width:100%;
    height:6rem;
    /*color is changed on jsx file*/
    background-color: transparent;
    /*child-positioning*/
    display:flex;
    justify-content: center;
    align-items: center;
    /*animations*/
    transition:.5s; 
}
#menu-icon:hover{
   background-color:rgba(54, 54, 54, 0.4);
   cursor: pointer;
}
#inner-menu-icon-container{
    /*size*/
    width:2.25rem;
    height:2.25rem;
    /*color*/
    background-color:transparent;

    /*child-positioning*/
    display:flex;
    justify-content: space-evenly;
    align-items: left;
    flex-direction: column;
}
#inner-menu-icon-container > div{
    border-radius:10%;
}
#inner-menu-icon-container > div:nth-child(1){
    height:2px;
    width:100%;
    background-color: white;
}
#inner-menu-icon-container > div:nth-child(2){
    height:2px;
    width:50%;
    background-color: white;
}